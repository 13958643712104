<template>
  <div>
    <div class="memu-wrap">
      <span class="is-active">勋章管理</span>
    </div>
    <el-card>
      <div class="medal-flex-box">
        <el-form inline>
          <el-form-item label="勋章名称">
            <el-input v-model="form.name" size="small" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getDataList">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="rest">重 置</el-button>
          </el-form-item>
        </el-form>
        <div><el-button type="primary" @click="addEditMedal">添加勋章</el-button></div>
      </div>
      <el-table
        v-loading="dataListLoading"
        :data="medalList"
        style="width: 100%;padding: 8px 0 ! important;"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
      >
        <el-table-column label="勋章名称" prop="name" />
        <el-table-column label="创建时间">
          <template slot-scope="{row}">
            <span>{{ row.createTime | F_formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="如何获得" prop="introduction" />
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" size="small" @click="viewMedalDetail(row)">查看</el-button>
            <el-button type="text" size="small" @click="addEditMedal(row)">编辑</el-button>
            <el-button type="text" size="small" @click="deleteMedal(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        style="padding:20px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 查看 -->
    <el-dialog :visible.sync="visible" title="查看勋章" width="50%">
      <div class="medal-detail-box">
        <img :src="medalDetail.picture">
        <div class="medal-title">{{ medalDetail.name }}</div>
        <div class="detail-box">勋章类别：{{ medalDetail.type == 1 ? '成就勋章' : '善行勋章' }}</div>
        <div class="detail-box">获得说明：{{ medalDetail.introduction }}</div>
        <div v-if="medalDetail.type == 1" class="detail-box">获得方式：{{ medalDetail.ways == 's' ? '分享数超' : '点赞数超' }}{{ medalDetail.waysDetail }}</div>
        <div v-else class="detail-box">获得方式：{{ medalDetail.ways == 'm' ? '捐款金额超' : '捐款次数超' }}{{ medalDetail.waysDetail }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible=false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 添加勋章 -->
    <add-edit-medal ref="addEditMedal" @addEditSuccess="getDataList" />
  </div>
</template>

<script>
import { medalDataList, deleteMedal } from '@/utils/api.js'
import AddEditMedal from './AddEditMedal'
export default {
  components: { AddEditMedal },
  data() {
    return {
      form: {
        companyId: sessionStorage.companyId,
        name: ''
      },
      medalList: [],
      dataListLoading: false,
      visible: false,
      current: 1,
      size: 10,
      total: null,
      medalDetail: {}
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    rest() {
      this.form.name = ''
      this.getDataList()
    },
    async getDataList() {
      this.dataListLoading = true
      const res = await medalDataList(this.form, this.current, this.size)
      this.dataListLoading = false
      if (res.code === 200) {
        this.medalList = res.data.records
        this.current = res.data.current
        this.size = res.data.size
        this.total = res.data.total
      } else {
        this.$message.error(res.message)
      }
    },
    viewMedalDetail(val) {
      this.visible = true
      this.medalDetail = val
    },
    addEditMedal(medalInfo) {
      this.$refs.addEditMedal.open(medalInfo)
    },
    deleteMedal(id) {
      this.$confirm('删除之后已经获得该勋章的将不再有该勋章, 是否继续删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const form = {}
        deleteMedal(form, id).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getDataList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getDataList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.memu-wrap {
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #000;
  span {
    margin-right: 20px;
    padding: 0 25px;
    line-height: 40px;
    display: block;
  }
  .is-active {
    background: rgba(18, 110, 255, 1);
    border-radius: 4px;
    color: #ffffff;
  }
}
.medal-flex-box {
  display: flex;
  justify-content: space-between;
}
.medal-detail-box {
  padding: 20px 0 50px 30px;
  font-size: 14px;
  color: #333333;
  border-bottom: 1px solid #E8E8E8;
  img {
    width: 124px;
    height: 124px;
  }
  .medal-title {
    font-size: 16px;
    margin: 10px 0 24px 0;
  }
  .detail-box {
    margin-bottom: 12px;
  }
}
.dialog-footer {
  text-align: center;
}
</style>
