<template>
  <div>
    <el-dialog :visible.sync="visible" :title="title" @close="closeHandle">
      <el-form ref="addForm" size="small" :model="addForm" :rules="rules" label-width="100px">
        <el-form-item label="勋章名称:" prop="name">
          <el-input v-model="addForm.name" />
        </el-form-item>
        <el-form-item label="勋章类别:" prop="type">
          <el-radio-group v-model="addForm.type">
            <el-radio label="1">成就勋章</el-radio>
            <el-radio label="2">善行勋章</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="获得说明:" prop="introduction">
          <el-input v-model="addForm.introduction" style="width: 80%" placeholder="请用一句话说明如何获得该勋章" />
        </el-form-item>
        <el-form-item label="勋章图片:" prop="picture">
          <div style="display:flex; align-items:center">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :data="uploadData"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <!-- <el-input v-model="addForm.picture" style="display: none" /> -->
              <img v-if="addForm.picture" :src="addForm.picture" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
            <span style="font-size:12px; color:#999999; margin-left:10px">只能上传一张，尺寸为264*264</span>
          </div>
        </el-form-item>
        <el-form-item label="获得方式:" prop="ways">
          <el-radio-group v-if="addForm.type == '1'" v-model="addForm.ways">
            <el-radio label="l">点赞数</el-radio>
            <el-radio label="s">分享数</el-radio>
          </el-radio-group>
          <el-radio-group v-else v-model="addForm.ways">
            <el-radio label="m">捐款金额</el-radio>
            <el-radio label="t">捐款次数</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="addForm.type && addForm.ways">
          <el-input v-show="addForm.ways === 'l'" v-model="addForm.waysDetail" v-only-number="{min: 0, precision: 0}" placeholder="请输入点赞数" />
          <el-input v-show="addForm.ways === 's'" v-model="addForm.waysDetail" v-only-number="{min: 0, precision: 0}" placeholder="请输入分享数" />
          <el-input v-show="addForm.ways === 'm'" v-model="addForm.waysDetail" v-only-number="{min: 0, precision: 0}" placeholder="请输入捐款金额" />
          <el-input v-show="addForm.ways === 't'" v-model="addForm.waysDetail" v-only-number="{min: 0, precision: 0}" placeholder="请输入捐款次数" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible=false">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addForm')">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addMedal, updateMedal } from '@/utils/api.js'
import httpRequest from '@/utils/httpRequest.js'
import { host } from '@/config'
export default {
  data() {
    return {
      title: '添加勋章',
      visible: false,
      addForm: {
        name: '',
        type: '',
        waysDetail: '',
        ways: '',
        introduction: '',
        picture: '',
        ways: ''
      },
      rules: {
        name: {
          required: true,
          message: '请输入勋章名称',
          trigger: 'blur'
        },
        type: {
          required: true,
          message: '请选择勋章类别',
          trigger: 'blur'
        },
        introduction: {
          required: true,
          message: '请输入获得说明',
          trigger: 'blur'
        },
        picture: {
          required: true,
          message: '请输入勋章图片',
          trigger: 'change'
        },
        ways: {
          required: true,
          message: '请选择获得方式',
          trigger: 'blur'
        }
      },
      action: httpRequest.api('/file/upload'),
      uploadData: {
        type: '2',
        imgModel: '6'
      }
    }
  },
  methods: {
    closeHandle() {
      this.addForm.name = ''
      this.addForm.type = ''
      this.addForm.introduction = ''
      this.addForm.picture = ''
      this.addForm.ways = ''
      this.addForm.waysDetail = ''
    },
    open(medalInfo) {
      console.log(medalInfo)
      if (medalInfo.id) {
        this.title = '编辑勋章'
        this.addForm = { ...medalInfo }
      } else {
        this.title = '添加勋章'
        this.addForm = {}
      }
      this.visible = true
    },
    async addEditSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.title === '添加勋章') {
            const res = await addMedal(this.addForm)
            this.visible = false
            if (res.code === 200) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.message)
            }
          } else {
            const res = await updateMedal(this.addForm)
            this.visible = false
            if (res.code === 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.message)
            }
          }
          this.$emit('addEditSuccess')
        }
      })
    },
    // 上传图片成功的回调
    handleAvatarSuccess(res) {
      console.log('上传成功了')
      // this.$set(this.addForm, 'picture', res.data)
      if (res.code === 200) {
        const path = `${host}/file/browse/${res.data}`
        this.$set(this.addForm, 'picture', path)
      } else {
        this.$message.error(res.message)
      }
    },
    beforeAvatarUpload(file) {
      console.log('上传之前调用')
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 4MB!')
      }
      return isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
.el-input {
  width: 35%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
</style>
