/*
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-04-01 11:41:59
 * @LastEditors: peter
 * @LastEditTime: 2022-06-21 09:22:11
 */
// export const host = (process.env.NODE_ENV === 'production' ? 'https://veteran.iif2f.com/api' : 'http://laobing.iif2f.com/api')
export const host =process.env.NODE_ENV === 'production'? 'http://veteran.iif2f.com/api': 'http://veteran.iif2f.com/api'


